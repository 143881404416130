.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
/*   pointer-events: none; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body{
  /* background: rgb(0,155,255);
  background: -moz-linear-gradient(90deg, rgba(0,155,255,1) 0%, rgba(203,235,255,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(0,155,255,1) 0%, rgba(203,235,255,1) 100%);
  background: linear-gradient(90deg, rgba(0,155,255,1) 0%, rgba(203,235,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#009bff",endColorstr="#cbebff",GradientType=1); */
}

.backGcolor_TOYTEK{
  background: rgb(223,243,255);
  background: -moz-linear-gradient(90deg, rgba(223,243,255,1) 0%, rgba(203,235,255,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(223,243,255,1) 0%, rgba(203,235,255,1) 100%);
  background: linear-gradient(90deg, rgba(223,243,255,1) 0%, rgba(203,235,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dff3ff",endColorstr="#cbebff",GradientType=1);
color: 'black';
}