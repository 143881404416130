.featurette-divider {
    margin: 5rem 0;
    color: white !important;
}
hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: .25;
}

.rowCaract {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
}

*, ::after, ::before {
    box-sizing: border-box;
}

@media (min-width: 62em) {
    .featurette-heading {
        margin-top: 7rem;
    }
}
@media (min-width: 40em) {
    .featurette-heading {
        font-size: 50px;
    }
}
.featurette-heading {
    letter-spacing: -.05rem;
}
.lh-1 {
    line-height: 1 !important;
}
.fw-normal {
    font-weight: 400 !important;
}
.h2, h2 {
    font-size: calc(1.325rem + .9vw);
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.containerCaracteristicas, .containerCaracteristicas-fluid, .containerCaracteristicas-lg, .containerCaracteristicas-md, .containerCaracteristicas-sm, .containerCaracteristicas-xl, .containerCaracteristicas-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1200px) {
    .containerCaracteristicas, .containerCaracteristicas-lg, .containerCaracteristicas-md, .containerCaracteristicas-sm, .containerCaracteristicas-xl {
        max-width: 100%;
    }
}


@media (min-width: 768px) {
    .order-md-1 {
        order: 1 !important;
    }
}

@media (min-width: 768px) {
    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
}
.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-top: var(--bs-gutter-y);
}
@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
img, svg {
    vertical-align: middle;
}


.text-body-secondary {
    --bs-text-opacity: 1;
    color: white !important;
}


@media (min-width: 1400px) {
    .containerCaracteristicas , .containerCaracteristicas -lg, .containerCaracteristicas -md, .containerCaracteristicas -sm, .containerCaracteristicas -xl, .containerCaracteristicas -xxl {
        max-width: 1320px;
    }
}
@media (min-width: 1200px) {
    .containerCaracteristicas , .containerCaracteristicas -lg, .containerCaracteristicas -md, .containerCaracteristicas -sm, .containerCaracteristicas -xl {
        max-width: 1140px;
    }
}
/* @media (min-width: 992px) {
    .containerCaracteristicas , .containerCaracteristicas -lg, .containerCaracteristicas -md, .containerCaracteristicas -sm {
        max-width: 960px;
    }
}
@media (min-width: 768px) {
    .containerCaracteristicas , .containerCaracteristicas -md, .containerCaracteristicas -sm {
        max-width: 720px;
    }
}
@media (min-width: 576px) {
    .containerCaracteristicas , .containerCaracteristicas -sm {
        max-width: 540px;
    }
} */
.containerCaracteristicas , .containerCaracteristicas -fluid, .containerCaracteristicas -lg, .containerCaracteristicas -md, .containerCaracteristicas -sm, .containerCaracteristicas -xl, .containerCaracteristicas -xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-right: auto;
    margin-left: auto;
}
*, ::after, ::before {
    box-sizing: border-box;
}