.text-light {
    color: #f8f9fa !important;
  }
  .text-center {
    text-align: center !important;
  }
/*   .bg-dark {
    background-color: #343a40 !important;
  } */

  .section-title {
    font-size: 3rem !important;
}

.container-fluid{
  padding-right: 15 !important;
  padding-left: 15 !important;
  
}