.pricing-header {
    max-width: 700px;
    color: #000 !important;
}
.text-center {
    text-align: center !important;
}
.ml-auto, .mx-auto {
    margin-left: auto !important;
}
.mr-auto, .mx-auto {
    margin-right: auto !important;
}
.pl-3, .px-3 {
    padding-left: 1rem !important;
}
.pb-3, .py-3 {
    padding-bottom: 1rem !important;
}
.pr-3, .px-3 {
    padding-right: 1rem !important;
}
.pt-3, .py-3 {
    padding-top: 1rem !important;
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
}
.h1, h1 {
    font-size: 2.5rem;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
/*     color: #000 !important; */
}
h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
    color:white !important;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
    color:white !important;
}