 .content-video {
/*    position: absolute; */
   width: 100%;
   height: 100%;
   top: 0;
   left:0;
   display: block;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   color: rgb(7, 0, 0);
 }
 
 #video3 {
   position: fixed;   
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   object-fit: cover;
 }