.rowCircle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
*, ::after, ::before {
    box-sizing: border-box;
}

.marketing .col-lg-4 {
    margin-bottom: 1.5rem;
    text-align: center;
}

@media (min-width: 992px) {
    .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    /* width: 100%; */
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
*, ::after, ::before {
    box-sizing: border-box;
}

.containerCircle {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
}

body{
    /* background-color: azure !important; */
  /*   color: black !important; */
}


@media (min-width: 992px) {
    .containerCircle, .containerCircle-lg, .containerCircle-md, .containerCircle-sm {
        max-width: 960px;
    }
}
@media (min-width: 768px) {
    .containerCircle, .containerCircle-md, .containerCircle-sm {
        max-width: 720px;
    }
}
@media (min-width: 576px) {
    .containerCircle, .containerCircle-sm {
        max-width: 540px;
    }
}
.containerCircle, .containerCircle-fluid, .containerCircle-lg, .containerCircle-md, .containerCircle-sm, .containerCircle-xl, .containerCircle-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-right: auto;
    margin-left: auto;
}