.mb-2 {
    margin-bottom: .5rem !important;
}

*, ::after, ::before {
    box-sizing: border-box;
}

.rounded {
    border-radius: var(--bs-border-radius) !important;
}
.mb-4 {
    margin-bottom: 1.5rem !important;
}
.border {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.position-relative {
    position: relative !important;
}
.shadow-sm {
    box-shadow: var(--bs-box-shadow-sm) !important;
}
.overflow-hidden {
    overflow: hidden !important;
}
.g-0, .gy-0 {
    --bs-gutter-y: 0;
}
.g-0, .gx-0 {
    --bs-gutter-x: 0;
}
.rowInfo {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
 /*    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x)); */
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
}

.p-4 {
    padding: 1.5rem !important;
}
.flex-column {
    flex-direction: column !important;
}
.position-static {
    position: static !important;
}
.d-flex {
    display: flex !important;
}
.col {
    flex: 1 0 0%;
}
.rowInfo>* {
    flex-shrink: 0;
    /* width: 100%; */
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-top: var(--bs-gutter-y);
}

.text-primary-emphasis {
    color: var(--bs-primary-text-emphasis) !important;
}
.mb-2 {
    margin-bottom: .5rem !important;
}
.d-inline-block {
    display: inline-block !important;
}
b, strong {
    font-weight: bolder;
}


.mb-auto {
    margin-bottom: auto !important;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
    /* color: #000 !important; */
}

.containerInfo {
    width: 100%;
 /*    padding-right: 15px;
    padding-left: 15px; */
    margin-right: auto;
    margin-left: auto;
  }

  

  .col-md-6 {
    position: relative;
  /*   width: 100%; */
    min-height: 1px;
    padding-right: 5px !important;
    padding-left: 5px !important;
}

@media (min-width: 992px) {
    .d-lg-block {
        display: block !important;
    }
   
}
@media (min-width: 992px) {
    .d-lg-block {
        display: block !important;
    }
}
/* .d-none {
    display: none !important;
} */
.col-auto {
    flex: 0 0 auto;
    width: auto;
    position: relative;
    min-height: 1px;
   padding-right: 15px;
   padding-left: 15px;
}



@media (min-width: 768px) {
    .h-md-250 {
        height: 250px;
    }
}
@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important;
    }
}
.rounded {
    border-radius: var(--bs-border-radius) !important;
}
.mb-4 {
    margin-bottom: 1.5rem !important;
}
.border {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.position-relative {
    position: relative !important;
}
.shadow-sm {
    box-shadow: var(--bs-box-shadow-sm) !important;
}
.overflow-hidden {
    overflow: hidden !important;
}
.g-0, .gy-0 {
    --bs-gutter-y: 0;
}
.g-0, .gx-0 {
    --bs-gutter-x: 0;
}
/* .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
} */

.p-4 {
    padding: 1.5rem !important;
}
.flex-column {
    flex-direction: column !important;
}
.position-static {
    position: static !important;
}
.d-flex {
    display: flex !important;
}
.col {
    flex: 1 0 0%;
}

@media (min-width: 768px) {
    .h-md-250 {
        height: 250px;
    }
}
@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important;
    }
}
.rounded {
    border-radius: var(--bs-border-radius) !important;
}
.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mb-0, .my-0 {
    margin-bottom: 0 !important;
    color: #000 !important;
}

.gap-1 {
    gap: .25rem !important;

}


.text-body-secondary {
    --bs-text-opacity: 1;
    color: #000;
}

.btn-outline-primary {
    --bs-btn-color: #0d6efd;
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0d6efd;
    --bs-btn-hover-border-color: #0d6efd;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0d6efd;
    --bs-btn-active-border-color: #0d6efd;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0d6efd;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0d6efd;
    --bs-gradient: none;
}

.mb-2 {
    margin-bottom: 0rem !important;
}